<template>
  <b-card no-body class="card-custom">
    <template #header>
      <div class="card-title">
        <h3 class="card-label">Orders</h3>
      </div>
    </template>

    <div class="card card-custom gutter-b card-stretch card-shadowless">
      <!-- User Interface controls -->

      <div class="card-body pt-0">
        <DataTable ref="dt" :value="items" dataKey="id" :lazy="true" :paginator="true" :rows="rowCount" class="mt-0"
          :totalRecords="totalRecords" @page="onPage($event)" @sort="onSort($event)" :loading="loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products">

          <b-card-header class="p-4">
            <b-row>
              <b-col lg="2" md="3" sm="6">
                <b-form-group class="m-0">
                    <b-form-select v-model="filters.order_status" placeholder="Order Status"
                      :options="statusList" @change="onFilter()"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" sm="6">
                <b-form-group class="m-0">
                    <b-form-select v-model="filters.payment_status" placeholder="Payment Status"
                      :options="paymentStatus" @change="onFilter()"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" sm="6">
                <b-form-group class="m-0">
                  <span class="p-input-icon-right w-100">
                  
                    <b-form-input v-model="filters.name" placeholder="Search ID, Name.." class="py-2"
                      @keydown.enter="onFilter()" />
                      <i class="las la-search"></i>
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-header>

          <template #empty>
            <div class="text-center text-muted py-5">Products not found.</div>
          </template>


          <Column field="sl" header="#" class="SL-n">
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template>
          </Column>
          <Column field="invoice" header="Invoice No." :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.invoice }}
            </template>
          </Column>
          <Column field="name" header="Customer Name" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.name }}
            </template>
          </Column>
          <Column field="phone" header="phone" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.phone }}
            </template>
          </Column>
          <Column field="count" header="Item Count" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.itemCount }}
            </template>
          </Column>
          <Column field="Price" header="Price" ref="order_search" :sortable="true">
            <template #body="slotProps">
              <div>{{ slotProps.data.total }}</div>
              
            </template>
          </Column>
          <Column field="order_search" header="Status" ref="order_search" :sortable="true">
            <template #body="slotProps">
              <div>{{ slotProps.data.status }}</div>
              
            </template>
          </Column>
          
          <Column field="action" header="Action" :sortable="true">
            <template #body="slotProps">
              <b-button  size="sm" pill  :to="`/order-status-form/${slotProps.data.id}`" class="py-2 px-4 border bg-white bg-hover-primary">Change Status</b-button>
              <b-button  size="sm" pill  :to="`/order-detail/${slotProps.data.id}`" class="py-2 px-4 border bg-white bg-hover-primary">Detail</b-button>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';

import OrderService from "@/core/services/api/order";

export default {
  components: {
    DataTable,
    Column,
    InputText,
    Dropdown
  },
  data() {
    return {
      statusList: [
        { value: null, text: 'All' }
      ],
      paymentStatus: [
        { value: null, text: 'All' },
        { text: "Created", value: "created" },
        { text: "Success", value: "success" },
        { text: "Failed", value: "failed" }
      ],
      order_search: null,
      order_type: null,
      orderList: [],
      imgPath: process.env.VUE_APP_API_STORAGE_URL,
      loading: false,
      totalRecords: 0,
      rowCount: 1,
      lazyParams: {},
    };
  },
  mounted() {
    this.loading = true;

    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters
    };

    this.$store.dispatch(SET_BREADCRUMB, [
    {
          title: "Dashboard",
          route: "/dashboard",
        },
        {
          title: "Order",
          route: "/order",
        },
    ]);
    this.getOrderList();
    this.getOrderStatusList();
  },
  computed: {
    filters: function () {
      var filters = {
        name: null,
        model: null,
        order_status: null,
      }
      return filters
    },
    items: function () {
      var items = [];
      this.orderList.map(e => {
        var status = e.orderStatusInfo.find((element) => element.order_status_id === e.order_status);
        var total = e.orderTotalInfo.find((element) => element.code === "grandTotal");
            items.push({
                    id : e.id,
                    name : e.name,
                    phone : e.phone,
                    invoice : e.invoice_prefix + e.invoice_no,
                    itemCount : e.orderProduct != undefined ? e.orderProduct.length : 0,
                    status : status != undefined ? status.order_status_name : "",
                    total : total != undefined ? total.value : "--",

            })
      })
      return items;
    }
  },
  methods: {
    async getOrderStatusList() {
        var response = await OrderService.getOrderStatusList();
        if(response){
					response.map((e)=>{
						this.statusList.push({
							text:e.order_status,
							value:e.id
						})
					});
				}
    },
    async getOrderList() {
      var response = await OrderService.getOrderList(this.lazyParams);
      if (response.data != undefined) {
        this.orderList = response.data;
        this.totalRecords = response.total;
        this.rowCount = response.per_page;
      } else {
        this.orderList = [];
        this.totalRecords = 0;
        this.rowCount = 1;
      }
      this.loading = false;
    },
    onPage(event) {
      this.loading = true;
      this.lazyParams = event;
      this.getOrderList();
    },
    onSort(event) {
      this.loading = true;
      this.lazyParams = event;
      this.getOrderList();
    },
    onFilter() {
      this.loading = true;
      this.lazyParams.filters = this.filters;
      this.getOrderList();
    },
  },
  watch: {
    // order_search(newValue) {
    // 	this.filters.order_search = newValue;
    // 	this.onFilter();
    // }
  }

};
</script>

<style lang="scss">
.p-datatable {
  .p-datatable-wrapper {
    .p-datatable-table {
      tr {
        td:last-child {
          width: 100px
        }
      }
    }
  }
}
</style>